import React from "react"
import "./index.scss"

const images = [
  {
    url: `/images/customers/walt-disney-world-resort.png`,
    alt: "Walt Disney World Resort",
  },
  { url: `/images/customers/four-seasons.png`, alt: "Four Seasons" },
  { url: `/images/customers/ferari.png`, alt: "Ferrari" },
  { url: `/images/customers/hilton.png`, alt: "Hilton" },
  { url: `/images/customers/ritz-carlton.png`, alt: "Ritz Carlton" },
]

export default function CustomerImages() {
  return (
    <div className="customer-images">
      <div className="grid">
        {images.map((image, i) => (
          <div className="grid-item" key={i}>
            <img src={image.url} alt={image.alt} />
          </div>
        ))}
      </div>
    </div>
  )
}
