import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import ParallaxSection from "@components/parallaxSection"
import CustomerImages from "@components/CustomerImages"
import ProjectGrid from "@components/ProjectGrid"
import ImageGrid from "@components/ImageGrid"
import IconTextList from "@components/IconTextList"

import {
  ContactForm,
  FormWrapper,
  RequestMoreInfo,
  RequestQuote,
} from "@components/Forms"

import { QuotePanel } from "../components/quotes"

const IndexPage = () => {
  return (
    <PageLayout
      heroTitle="CUSTOM ARCHITECTURAL DECORATIVE GLASS"
      heroBackgroundImage="/images/home/header1-home.jpg"
    >
      <SEO
        title="Custom Architectural Decorative Glass | ArtVue Glass"
        ogDescription="ArtVue Glass is a leading custom decorative glass manufacturer. We specialize in supplying architectural glass to differentiate your interior space. Call today 941-378-1762!"
      />
      <SingleColumnBlock
        textPadded
        centered
        text={
          <div>
            <h2>
              Trust ArtVue Glass to deliver high quality interior glass and
              outstanding customer service.
            </h2>
          </div>
        }
      />
      <TextBlock
        textPadded
        textRight={
          <div>
            <h2>Custom Glass to Make Your Design a Reality</h2>
            <p>
              Decorating a home or business? Feeling overwhelmed, especially
              when working with glass?
            </p>
            <p>
              There are many glass products to choose from and it's hard to
              determine what will best fit your needs.
            </p>
            <p>
              With our skilled staff, we will help you find the perfect solution
              for your space. We offer a wide range of decorative glass pieces
              that include mirrors, pattern glass, back painted, and much more.
              Our custom products are made by our team with years of experience
              in creating beautiful spaces. You'll love how easy it is to work
              with us!
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Contact Us Today",
                size: "tiny",
                icon: "phone",
              }}
            >
              <ContactForm />
            </FormWrapper>
          </div>
        }
        textLeft={
          <div>
            <img
              src="/images/home/lobby-glass.jpg"
              alt="Decorative Wholesale Glass & Mirror"
            />
          </div>
        }
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h2>High Quality, Reliable Supplier You Can Trust</h2>
            <p>
              When you’re trying to win your next big project, the last thing
              you need is for your glass supplier to let you down.
            </p>
            <p>
              We know how important it is to have reliable suppliers that can
              deliver on time and offer excellent customer service. You
              shouldn’t have to worry about whether or not they will be there
              when you need them most.
            </p>
            <p>
              Our team has over 25 years of experience in the architectural
              glass industry. With our expertise and knowledge, we can help
              ensure that your projects are completed smoothly, quickly, and
              hassle-free so that you can focus on what really matters –
              delivering and installing beautiful custom pieces!
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Request More Info",
                size: "tiny",
                icon: "calendar",
              }}
            >
              <RequestMoreInfo />
            </FormWrapper>
          </div>
        }
        textRight={
          <div>
            <img
              src="/images/home/custom-mirror-glass-wall.jpg"
              alt="Decorative Wholesale Glass & Mirror"
            />
          </div>
        }
      />
      <div className="section textured">
        <ImageGrid
          content={productGridItems}
          title="Products"
          description="At ArtVue Glass, our custom projects start with one of our product families and then expand from there. Whether you are looking for popular types of glass or some hard-to-find items, we carry a large inventory so you can find the perfect materials for any time of job!"
          itemsPerRow={3}
        />
      </div>

      <PageWrapper color="grey">
        <SingleColumnBlock
          centered
          text={
            <div>
              <h1>Customers</h1>
              <h2>Bringing Your Glass Design Vision to Reality</h2>
              <p>
                We work with top customers, designers and decorators to help
                them create a unique space using our large selection of
                decorative glass products. With our years of experience in
                assisting in the design process, we create a finished product
                that will meet your design aesthetics, fit and functionality the
                first time.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <div style={{ margin: "0 0 0 0", background: "#eee" }}>
        <CustomerImages />
      </div>
      <PageWrapper>
        <ProjectGrid
          content={recentProjectItems}
          title={"Projects"}
          description={
            "What do we specialize in? You guessed it. Glass! We have the experience and know-how to bring your creations from concept to reality with our expertise, cutting edge capabilities, and artistic flair. Check out these project examples to see the possibilities using glass."
          }
        />
      </PageWrapper>
      <ParallaxSection
        src={`/images/home/expresso-antique-mirror.jpg`}
        height={300}
      />
      <SingleColumnBlock
        textPadded
        centered
        text={
          <div>
            <h2>We Are the Glass Experts You Can Trust</h2>
            <p>
              We have perfected the art of providing customers a reliable glass
              product that they can build their reputation on. Our factory takes
              pride in producing high-quality and innovative products with an
              attention to detail, which we know will please our valued clients.
              No matter what your project entails, think twice before you go
              anywhere else other than ArtVue Glass for all your needs!
            </p>
          </div>
        }
      />
      <IconTextList
        items={[
          {
            key: 1,
            title: "1. Send Us Your Requirements",
            description:
              "Call or email us your projects details for our review.",
            icon: "/images/home/icon-bid.png",
          },
          {
            key: 2,
            title: "2. Receive a Quote We Stand Behind",
            description:
              "We will give you all the details you need to complete your project.",
            icon: "/images/home/icon-quote.png",
          },
          {
            key: 3,
            title: "3. Review the Proposal",
            description:
              "Still have questions? Schedule a brief consultation to ensure no stone (or piece of glass) is left unturned.",
            icon: "/images/home/icon-review.png",
          },
          {
            key: 4,
            title: "4. Deliver On Time",
            description:
              "Enjoy a hassle-free project and impress your customers, colleagues, and friends with a high-quality finished product.",
            icon: "/images/home/icon-deliver.png",
          },
          {
            key: 5,
            title: "5. Experienced Customer Support",
            description:
              "Our knowledgeable team will handle any questions along the way. We have your back (and back painted glass too)!",
            icon: "/images/home/icon-support.png",
          },
        ]}
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <p>
              We will work with you to create a custom solution for your needs,
              whether it is big or small. Our knowledgeable staff members have
              the expertise needed to provide what each customer desires most.
            </p>
            <FormWrapper
              modal
              buttonProps={{
                content: "Request A Quote",
                size: "tiny",
                icon: "file pdf outline",
              }}
            >
              <RequestQuote />
            </FormWrapper>
          </div>
        }
      />
      <QuotePanel
        title="Customer Testimonials"
        text="Hello World"
        person="John Doe"
      />
    </PageLayout>
  )
}

export default IndexPage

const productGridItems = [
  {
    image: `/images/custom-glass/custom-glass.jpg`,
    text: "Custom Glass",
    url: `/custom-glass`,
  },
  {
    image: `/images/custom-glass/antique-mirror.jpg`,
    text: `Antique Mirror`,
    url: "/antique-mirror",
  },
  {
    image: `/images/custom-glass/back-painted-glass.jpg`,
    text: "Back Painted Glass",
    url: `/back-painted-glass`,
  },
  {
    image: `/images/crackle-glass/crackle-glass-sample.jpg`,
    text: "Crackle Glass",
    url: `/crackle-glass`,
  },
  {
    image: `/images/custom-glass/laminated-glass.jpg`,
    text: "Laminated Glass",
    url: `/laminated-glass`,
  },
  {
    image: `/images/custom-glass/pattern-glass.jpg`,
    text: "Pattern Glass",
    url: `/pattern-glass`,
  },
]

const recentProjectItems = [
  {
    image: `/images/projects/ritz-carlton-project-home.jpg`,
    text: "Ritz-Carlton Key Biscayne, Miami, FL",
    url: `/textured-pattern-glass-ritz-carlton-miami-fl`,
    position: "left",
    featured: true,
  },
  {
    image: `/images/projects/top-draft-project1.jpg`,
    text: "Top Draft Sports Bar, Atlanta, GA",
    url: `/back-painted-glass-atlanta-ga-top-draft-sports-bar`,
    position: "right",
  },
  {
    image: `/images/projects/westgate-resorts-project1.jpg`,
    text: "Westgate Resorts, Orlando, FL",
    url: `/laminated-glass-orlando-fl-westgate-resorts`,
    position: "left",
  },
  {
    image: `/images/projects/pelicans-nest-project1.jpg`,
    text: `Pelican’s Nest Golf Club, Bonita Springs, FL`,
    url: "/pattern-glass-pelicans-nest-golf-club-bonita-springs-fl",
    position: "right",
  },
]
