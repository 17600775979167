import React, { useState } from "react"
import { Link } from "gatsby"
import { Button } from "semantic-ui-react"
import "./index.scss"

export default function ProjectGrid({ content, title, description }) {
  const featuredItem = content.find(f => f.featured)
  const altItems = content.filter(f => !f.featured)

  return (
    <div>
      <h2
        className="title"
        style={{ margin: "5px auto", width: "fit-content" }}
      >
        {title}
      </h2>
      <div className="project-grid-wrapper">
        <div className="big-picture">
          {featuredItem && (
            <Link to={featuredItem.url}>
              <img src={featuredItem.image} />
            </Link>
          )}
        </div>
        <div className="description-and-photos">
          <div className="projects-description">
            <p>{description}</p>
          </div>
          <div className="alt-images">
            {altItems.map(i => {
              return (
                <Link to={i.url} className="alt-image">
                  <img src={i.image} />
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

ProjectGrid.defaultProps = {
  content: [],
  listContent: false,
  title: "",
  description: "",
}
