import React from "react"
import "./index.scss"

export default function IconTextList({ items }) {
  return (
    <div className="icon-text-list">
      {items.map(item => (
        <div className="item" key={item.key}>
          <img src={item.icon} />
          <div className="content">
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  )
}

IconTextList.defaultProps = {
  items: [],
}
